import React from 'react';

// libraries
import { css } from '@emotion/core';

import Head from 'components/Head';
import MainLayout from 'components/MainLayout';
import { button } from 'style';

import background from 'images/404.svg';

export default function SdkLanding() {
  return (
    <MainLayout>
      <Head title="Confirmed" />
      <section css={hero}>
        <h2 css={title}>Confirmation Complete!</h2>
        <a href="/login" css={button({ kind: 'brand' })}>
          Log In
        </a>
      </section>
    </MainLayout>
  );
}

const hero = css`
  flex: 1;
  background: center url(${background}) no-repeat;
  background-size: cover;
  text-align: center;
  min-height: 60rem;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const title = css`
  margin-bottom: 1.2rem;
`;
